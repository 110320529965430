import React, {Component} from "react";
import '../../styles/components/texts/full-name.less';

export default class FullName extends Component {
    render() {
        return (
            <div className="full-name">
                <span id="first-name">Tejaswi</span>
                <span id="last-name"> Anupindi</span>
            </div>
        )
    }
}