import React from "react"
import '../../styles/components/backgrounds/bubbly.css'

export default class Bubbly extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    render() {
        return (
            <div id="bubbly">
                <canvas ref={this.canvasRef}/>
            </div>
        )
    }

    componentDidMount() {
        require("bubbly-bg");
        window.bubbly({
            colorStart: '#dfdfdf',
            colorStop: '#efefef',
            blur: 0,
            bubbles: 15,
            angleFunc: () => Math.random() * Math.PI * 2,
            compose: 'source-over',
            radiusFunc: () => 100 + Math.random() * 25,
            bubbleFunc: () => `hsla(${Math.random() * 300}, 100%, 50%, 0.2)`,
            canvas: this.canvasRef.current
        })
    }
}
