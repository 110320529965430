import React, {Component} from "react"
import Bubbly from "../components/backgrounds/bubbly"
import FullName from "../components/texts/full-name";
import {Circ, Sine, Power0, Power4, TimelineLite, TweenLite} from 'gsap/all';
import '../styles/pages/index.less';
import 'uikit/dist/css/uikit.css';
import $ from "jquery";
import {Helmet} from "react-helmet";

export default class Home extends Component {
    render() {
        return (
            <div className="index-page" id="index-page" style={{"visibility": "hidden"}}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Tejaswi Anupindi</title>
                    <link rel="canonical" href="https://anupindi.dev"/>
                </Helmet>
                <div className="landing-page">
                    <Bubbly/>
                    <div className="uk-position-center">
                        <div className="hello">
                            <FullName/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    landingPageAnimations() {
        // Scroll Magic Controller and Greens sock Time lines.
        let presentationTimeline = new TimelineLite({paused: 'true'});

        presentationTimeline.add(TweenLite.from('.hello .full-name #first-name', 1, {
            ease: Power0.easeOut,
            opacity: 0
        }), 0.5);
        presentationTimeline.add(TweenLite.from('.hello .full-name #last-name', 1, {
            ease: Power4.easeOut,
            opacity: 0,
            right: '7vw'
        }), '-=0.2');
        presentationTimeline.add("mark1", '=0.0');
        presentationTimeline.add(TweenLite.from('.hello .full-name', 0.3, {
            textShadow: '0px 0px 0px rgba(255,255,255,0)',
            ease: Circ.easeOut
        }), '+=0');
        presentationTimeline.add(TweenLite.to(window, 1.5, {
            scrollTo: {y: "#cards", autoKill: false},
            ease: Sine.easeInOut
        }), "+=0.0");
        presentationTimeline.play();
    }

    componentDidMount() {
        require('animation.gsap');
        require('debug.addIndicators');

        this.landingPageAnimations();

        $("#index-page").css("visibility", "visible");
    }
}
